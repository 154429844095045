import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';
import { InputAdornment, Theme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import '../../styles/website_fonts.css';
import CloseIcon from '@mui/icons-material/Close';
import schemaValidate from '../../services/schemaValidate';
import { IQFormLayout } from '../IQFormLayout/IQFormLayout';

export enum Adornment {
  START_ADORNMENT = 'START',
  END_ADORNMENT = 'END',
}

export interface IQFormInputProps {
  /**
   * Unique ID of this component
   */
  id: string;

  /**
   * Label for the input field
   */
  labelText: string;

  /**
   * Name field used in the react hook form and yup schema
   */
  name: string;

  /**
   * Icon to show at the start or end of the input field
   */
  adornmentIcon?: any;

  fontLabelWeight?: string;

  /**
   * Adornment icon position
   */
  adornmentPosition?: Adornment.END_ADORNMENT | Adornment.START_ADORNMENT;

  /**
   * On focus function callback
   */
  onFocus?: FocusEventHandler<HTMLInputElement>;

  onChange?: ChangeEventHandler<HTMLInputElement>;

  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;

  /**
   * On blur function callback
   */
  onBlur?: FocusEventHandler<HTMLInputElement>;

  /**
   * Whether to use the standard error text message. Set to false if
   * using a custom error message
   */
  showError?: boolean;

  /**
   * Whether to use the 'password' input mask
   */
  obscureValue?: boolean;

  /**
   * If you are using a fieldset and the error is deeply nested, pass that direct route here
   * E.g.
   * Field array phoneNumbers, field name is phoneNumber[0].number.
   * Pass errors.phoneNumbers[0].number
   */
  directErrorReference?: { message: any };

  /**
   * Descriptive text displayed as a note below the input
   */
  subDescription?: string;

  /**
   * Tooltip text, if present a standard ? icon will be used with tooltip functionality.
   */
  tooltipText?: string;

  /**
   * Placeholder text for the input
   */
  placeholder?: string;

  /**
   * Whether this field should be disabled
   */
  disabled?: boolean;

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  tooltipPaddingBottom?: number;

  tooltipArrow?: boolean;

  /**
   * Whether this is full width
   */
  fullWidth?: boolean;

  /**
   * The theme defined in parent app
   */
  theme: Theme;
  params?: any;
  schema?: any;
  customValidation?: any;

  /**
   * The maximum number of characters that can be entered
   * into this field.
   */
  maxLength?: number;
  /**
   * Shows a close button after the user enters 3 characters
   * after the user clicks the button, it should clear what the user has entered
   */
  clearable?: boolean;
  textId?: string;
  inputRadius?: string;
}

type StateProps = {
  disabled: boolean;
  hasError: boolean;
  radius?: string;
};

const StartAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StateProps>`
  position: relative;
  top: 20px;
  left: 10px;
  & svg {
    fill: ${(props) => (props.disabled
    ? props.theme.palette.action.disabledBackground
    : props.hasError
      ? props.theme.palette.error.main
      : props.theme.palette.text.secondary)};
  }
`;

const InputWrapper = styled('div')<StateProps>`
  background-color: ${(props) => props.theme.palette.common.white};
  border-width: 1px;
  border-style: solid;
  border-radius: ${(props) => (props.radius && props.radius)};
  border-color: ${(props) => (props.disabled
    ? props.theme.palette.action.disabledBackground
    : props.hasError
      ? props.theme.palette.error.main
      : props.theme.palette.text.primary)};
  &:focus-within {
    border-color: ${(props) => (props.hasError
    ? props.theme.palette.error.main
    : props.theme.palette.primary.main)};
    overflow: hidden;
    box-sizing: border-box;
    & svg {
      fill: ${(props) => (props.hasError
    ? props.theme.palette.error.main
    : props.theme.palette.primary.main)};
    }
  }
`;

const EndAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<StateProps>`
  position: relative;
  bottom: 22px;
  right: 0;
  left: calc(100% - 42px);
  & svg {
    fill: ${(props) => (props.disabled
    ? props.theme.palette.action.disabledBackground
    : props.hasError
      ? props.theme.palette.error.main
      : 'inherit')};
  }
`;

const FormInput = styled('input')<StateProps>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  border: 0;
  height: 38px;
  border-radius: ${(props) => (props.radius && props.radius)};
  color: ${(props) => (props.disabled
    ? props.theme.palette.action.disabledBackground
    : props.theme.palette.text.primary)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
  font-size: 16px;
  width: calc(100% - 16px);
  padding: 0 8px;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => (props.disabled
    ? props.theme.palette.action.disabledBackground
    : props.theme.palette.text.secondary)};
  }
  // Hide placeholder on focus
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  }
  /* FF 4-18 */
  &:focus::-moz-placeholder {
    color: transparent;
  }
  /* FF 19+ */
  &:focus::-ms-input-placeholder {
    color: transparent;
  }
  /* IE 10+ */
  // Prevent the chrome autofill color change
  -webkit-box-shadow: 0 0 0 30px white inset;
`;

const FormInputWithStartAdorn = styled(FormInput)`
  width: calc(100% - 64px);
  position: relative;
  left: 50px;
  padding-left: 0;
  padding-right: 14px;
`;

const FormInputWithEndAdorn = styled(FormInput)`
  width: calc(100% - 60px);
`;

type CloseButtonProps = {
  hasError: boolean;
  disabled: boolean;
  onClick: () => void;
};

const CloseButton = ({ hasError, disabled, onClick }: CloseButtonProps) => (
  <EndAdornment onClick={onClick} position="end" hasError={hasError} disabled={disabled} sx={{ cursor: 'pointer', bottom: '19px !important' }}>
    <CloseIcon />
  </EndAdornment>
);

/**
 * Component for a basic form input field
 *
 * See above IQFormInputProps for param info
 */
export const IQFormInput = ({
  id,
  textId,
  labelText,
  fontLabelWeight,
  name,
  adornmentIcon = null,
  adornmentPosition = Adornment.START_ADORNMENT,
  onBlur = () => {},
  onFocus = () => {},
  onChange,
  onKeyDown,
  showError = true,
  obscureValue = false,
  directErrorReference,
  subDescription,
  tooltipPlacement,
  tooltipText = null,
  tooltipPaddingBottom,
  tooltipArrow = true,
  placeholder = '',
  disabled = false,
  schema,
  params,
  fullWidth = false,
  customValidation,
  maxLength,
  clearable,
  inputRadius
}: IQFormInputProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  let hasError = null;
  let errorMessage = '';

  const [hasEnoughLength, setHasEnoughLength] = useState(false);

  if (directErrorReference) {
    hasError = !!directErrorReference;
    errorMessage = hasError ? directErrorReference.message : '';
  } else {
    hasError = !!errors[name];
    errorMessage = hasError ? `${errors[name].message}` : '';
  }

  const onCloseClick = () => {
    setValue(name, '');
    setHasEnoughLength(false);
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (clearable && e.currentTarget.value.length >= 3) {
      setHasEnoughLength(true);
    } else if (clearable) {
      setHasEnoughLength(false);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const isStartAdornment = adornmentPosition === Adornment.START_ADORNMENT;

  const adornment = isStartAdornment ? (
    <StartAdornment position="start" hasError={hasError} disabled={disabled}>
      {adornmentIcon}
    </StartAdornment>
  ) : (
    <EndAdornment position="end" hasError={hasError} disabled={disabled}>
      {adornmentIcon}
    </EndAdornment>
  );

  const StyledFormInput = adornmentIcon
    ? isStartAdornment
      ? FormInputWithStartAdorn
      : FormInputWithEndAdorn
    : FormInput;

  return (
    <IQFormLayout
      textId={textId}
      fullWidth={fullWidth}
      showError={hasError && showError}
      errorMessage={errorMessage}
      labelText={labelText}
      tooltipText={tooltipText}
      fontLabelWeight={fontLabelWeight}
      subDescription={subDescription}
      tooltipPlacement={tooltipPlacement}
      tooltipPaddingBottom={tooltipPaddingBottom}
      tooltipArrow={tooltipArrow}
    >
      <InputWrapper onBlur={onBlur} hasError={hasError} disabled={disabled} radius={inputRadius}>
        {adornmentIcon && isStartAdornment && adornment}
        <StyledFormInput
          {...register(name, {
            validate: (value) => schemaValidate(value, name, schema, params, customValidation),
          })}
          radius={inputRadius}
          type={obscureValue ? 'password' : 'text'}
          id={id}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={disabled}
          hasError={hasError}
          maxLength={maxLength}
          {...(onChange && { onChange })}
          onKeyDown={onInputKeyDown}
        />
        {adornmentIcon && !isStartAdornment && adornment}
        {clearable && hasEnoughLength && (
          <CloseButton hasError={hasError} disabled={disabled} onClick={onCloseClick} />
        )}
      </InputWrapper>
    </IQFormLayout>
  );
};

export default IQFormInput;
