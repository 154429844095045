import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import IQFormInput from '../../form/IQFormInput/IQFormInput';
import IQTheme from '../../styles/IQTheme';
import { useValidationContext } from '../../providers/ValidationProvider';
import { Adornment } from '../IQInputWebURL/IQInputWebURL';
import { getError } from '../../error/ErrorFunctions';

export interface IQInputEmailProps {
  /**
   * Input label
   */
  label: string
  /**
   * Input field name
   */
  field: string

  /**
   * Input required
   */
  required?: boolean
  /**
   * Default value
   */
  defaultValue?: string

  /**
   * Placeholder text for the input
   */
  placeholder?: string;

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  /**
   * Tooltip Text
   */
  tooltipText?: string;

  tooltipPaddingBottom?: number;
  /**
   * Additional parameters for custom validations (Optional)
   */
  params?: string
  /**
   * Properties for react-hook-forms / yup integration
   */
  theme?: Theme
  [rest: string]: any
}

export function IQInputEmail({
  id,
  textId,
  field,
  label,
  defaultValue,
  placeholder = '',
  required = false,
  params,
  tooltipPlacement,
  tooltipText,
  adornmentIcon,
  tooltipPaddingBottom,
  ...rest
}: IQInputEmailProps) {
  const { schema } = useValidationContext();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [customValidation, setCustomValidation] = useState(yup.string().email());
  const theme = IQTheme;
  useEffect(() => {
    let newValidation = yup.string().email('Email is not valid');
    if (required) {
      newValidation = newValidation.required();
    }

    setCustomValidation(newValidation);
    clearErrors(field);
  }, [required]);

  return (
    <IQFormInput
      id={id}
      textId={textId}
      name={field}
      directErrorReference={getError(errors, field)}
      labelText={label}
      theme={theme}
      schema={schema}
      customValidation={customValidation}
      showError={getError(errors, field) && true}
      placeholder={placeholder}
      adornmentIcon={adornmentIcon}
      adornmentPosition={Adornment.START_ADORNMENT}
      tooltipPlacement={tooltipPlacement}
      tooltipText={tooltipText}
      {...rest}
      tooltipPaddingBottom={tooltipPaddingBottom}
    />
  );
}

export default IQInputEmail;
